import React, { useLayoutEffect, lazy, Suspense, useState } from 'react';
import {
  MoreOutlined, KeyOutlined, LoginOutlined, UserOutlined, UnlockOutlined
} from '@ant-design/icons';
import {Button, Dropdown, Layout, theme, Spin, Space, Avatar, Input, Badge, Modal, Col, Row, Form,} from 'antd';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import styled from 'styled-components';
import logo from '../Assets/Images/logo.png';
import { useDispatch } from "react-redux";
import API from '../Store/Api/ApiService';
import bell from '../Assets/Images/bell.png';
import message from '../Assets/Images/message.png';
import { authrouter, commonrouter } from '../Store/Api/router';
import crud_service from '../Store/Api/CrudService';
const { Search } = Input;
const { Header, Content, Footer } = Layout;

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("persist:root"))?.admin;
const isAuthenticated = user && JSON.parse(user)?.isAuthenticated;
let userData = {};
if (isAuthenticated === undefined) {
  userData = {};
} else {
  userData = JSON.parse(user);
}

// console.log("userData", userData?.admin?.data);

const UserName = userData?.admin?.data?.first_name ? userData?.admin?.data?.first_name : userData?.admin?.data?.display_name;

  const crud = new crud_service();
  const api = new API();
  const d = new Date();
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location.pathname]);
    return children;
  };

  const logout = () => {
    api.logout(dispatch);
    // window.location.reload();
    window.location.href = "/login"
  }
  const changePasword = () => {
    setIsModalOpen(true);
    form.setFieldValue("_id", userData?.admin?.data?._id);
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = (values) => {

    crud.update('masteruser', values["_id"], values, async (err, res) => {
      // console.log("resss->", res?.data)

      if (res?.data?.success) {
        message.success(res?.data?.message);                
        setIsModalOpen(false);
        form.resetFields();
        // console.log("res", res?.data?.message)
      } else if (res?.response?.status === 500) {
        message.error(res?.response?.data?.message);
      } else {
        message.error("Something went wrong!. please try again later.");
        setIsModalOpen(false);
      }
    })
  }

  const items = [
    {
      label: <><UserOutlined /> My Profile</>,
      key: '0',
    },
    {
      label: <><div onClick={changePasword}><KeyOutlined /> Change Password </div> </>,
      key: '1',
    },
    {
      label: <><div onClick={logout}><LoginOutlined /> Logout</div></>,
      key: '2',
    },
  ];

  //component
  const MenuBar = lazy(() => import("../Component/MenuBar"));
  const MobileMenu = lazy(() => import("../Component/MobileMenu"));



  return (
    <React.Fragment>
      <Router basename="/">
        <Wrapper>
          <Suspense
            fallback={
              <div className="suspense_wrap">
                <Spin tip="Loading" size="small" />
              </div>
            }
          >
            {isAuthenticated === true ? (
              <DashboardSection>
                <Layout
                  style={{
                    minHeight: "100vh",
                  }}
                >
                  <MenuBar />
                  <Layout className="site-layout">
                    <Header
                      style={{
                        padding: 0,
                        background: colorBgContainer,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        zIndex: 1000,
                      }}
                    >
                      <div className="header_align">
                        <div className="header_left">
                          <div className="header_left_menu">
                            <MobileMenu />
                          </div>
                          <Link to="/">
                            <img src={logo} alt="ecDigi Technology" />
                          </Link>
                        </div>
                        <div className="header_right">
                          {/* <Search
                            placeholder="Search"
                            size="middle"
                            className="serach_head_btn"
                            autoComplete='off'
                          /> */}
                          <div></div>
                          <div className="right_side_bar">
                            <div>
                              <p style={{ fontWeight: "bolder" }}>{UserName}</p>
                              </div>
                            {/* <div className="batch_align">
                              <Badge count={0} showZero size="small">
                                <img src={bell} alt="Notification" />
                              </Badge>
                              <Badge count={0} showZero size="small">
                                <img src={message} alt="Message" />
                              </Badge>
                            </div> */}
                            <Dropdown
                              menu={{
                                items,
                              }}
                              trigger={["click"]}
                            >
                              <a
                                onClick={(e) => e.preventDefault()}
                                className="account_name"
                              >
                                <Space>
                                  <Avatar
                                    className="avator_profile"
                                    size="small"
                                    style={{ backgroundColor: "#87d068" }}
                                    icon={<UserOutlined />}
                                  />
                                  <MoreOutlined />
                                </Space>
                              </a>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Header>

                    <Content
                      style={{
                        margin: "0 16px",
                        padding: "80px 0px 50px 0px",
                      }}
                    >
                      <Routes>
                        {
                          authrouter?.map((item, index) => {
                            return (
                              <Route exact path={item.path} element={item.element} key={index} />
                            )
                          })
                        }
                      </Routes>
                    </Content>

                    <Footer
                      style={{
                        textAlign: "center",
                        fontFamily: "q_bold",
                        fontSize: "13px",
                      }}
                    >
                      ©{d.getFullYear()} Developed by{" "}
                      <a
                        href="https://ecdigi.com/"
                        title="ecDigi Technologies"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "var(--bg)", fontWeight: 600 }}
                      >
                        ecDigi Technologies
                      </a>
                    </Footer>
                  </Layout>
                </Layout>
              </DashboardSection>
            ) : (
              <Routes>
                {
                  commonrouter?.map((item, index) => {
                    return (
                      <Route exact path={item.path} element={item.element} key={index} />
                    )
                  })
                }

              </Routes>
            )}
          </Suspense>
        </Wrapper>        
      </Router>
      <Modal
            title="Change Password"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Save"
            footer={null}
            width={350}
          >
            <Form
              name="update"
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <Row style={{width: "100%"}}>
               
                <Col className="gutter-row" xxl={24} xl={24} lg={24} md={24} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item
                    label="id"
                    name="_id"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label="Email"
                    name="email_id"
                  >
                    <Input name="email_id" />
                  </Form.Item>
                </Col>       
                <Col className="gutter-row" xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                  >
                    <Input />
                  </Form.Item>
                </Col>        
              </Row>
              <Button type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form>
          </Modal>
    </React.Fragment>
  );
}

export default Dashboard;


const DashboardSection = styled.section`
  
  #components-layout-demo-side .logo {
    height: 25px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .D_Header {
    position: sticky;
    top: 0;
    left: 0px;
    width: 100%;
    z-index: 100;
  }
  .site-layout .site-layout-background,
  .ant-layout-sider,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub,
  .ant-layout-sider-trigger {
    background: #fff;
  }
  .logo {
    height: 55px;
    border-bottom: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .LayoutSection {
    min-height: 100vh;
  }
  .ant-layout-header {
    background: #fff;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: normal;
    border-bottom: 1px solid #f5f5f5;
    height: 55px;
  }
  .ant-layout,
  body {
    background: #f6f9ff;
  }
  .ant-layout-sider-trigger {
    color: #f6f9ff;
    border-top: 1px solid #f2f2f2;
    background: #000;
    z-index: 120;
    height: 40px;
    line-height: 40px;
}

  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub,
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark .ant-menu-item > span > a {
    color: #000;
  }

  .ant-layout-footer {
    padding: 12px 15px;
    background: #f6f9ff;
    color: #000;
    text-align: center;
    border-top: 1px solid #f5f5f5;
  }
  .Contents {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 24px;
    margin: 0px 0 0 0;
  }
  .BreadCrumb {
    margin: 0 0 30px;
  }
  .ant-layout-sider-children {
    position: fixed;
    width: 200px;
    z-index: 101;
    top:0;
  }
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light,
  .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    border-right: 1px solid #f0f0f0;
    height: 85vh;
    min-height: 85vh;
    max-height: 85vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 0 20px;
  }

  /* width */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar-track,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar-thumb,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar-thumb:hover,
  .ant-layout-sider-children
    .ant-menu.ant-menu-inline-collapsed::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger
    .ant-layout-sider-children {
      width: 60px;
  }
  .ant-menu-item-selected {
    background-color: rgb(245 146 9 / 10%);
    margin: 0 !important;
    width: 100% !important;
    border-radius: 0;
}

.ant-menu-item-selected .ant-menu-item-icon svg {
  color: var(--bg);
}
.ant-menu-title-content {
  font-size: 13px;
  font-family: "q_bold";
}
.ant-menu-item-selected .ant-menu-title-content {
  color: var(--bg);
}
.ant-menu-item {
    height: 37px;
    line-height: 37px;
    width: 100% !important;
    border-radius: 0;
    margin: 5px 0 !important;
}
.ant-menu-submenu-title {
  margin: 5px 0;
  height: 37px !important;
    line-height: 37px !important;
  width: 100% !important;
    border-radius: 0;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
  flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger .ant-layout-sider-trigger {
  width: 60px !important;
}
.ant-menu-sub .ant-menu-title-content {
  font-family: "q_medium" !important;
}

.header_align {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 5px 17px;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 5px rgb(0 0 0 / 12%);
}
.header_align .header_left {
  width: 235px;
  display: flex;
  align-items: center;
  gap:15px;
}
.header_left_menu {
  display: none;
}
.header_align .header_left svg {
  font-size: 20px;
  cursor: pointer;
}
.header_align .header_right {
  width: 100%;
  display: flex;
  gap: 20px;
 justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.serach_head_btn {
  width: 220px;
}
.header_align .header_left img {
  height: 35px;
}

.account_name .ant-space-item {
  font-size: 13px;
    font-family: "q_bold";
    color: #000;
    width: max-content;
    display: inline-block;
}

.header_align .header_right .ant-input {
  padding: 2px 11px;
}
.header_align .header_right .ant-input-group-addon button {
  height: 28px;
}

.avator_profile {
  width: 28px;
    height: 28px;
    line-height: 25px;
}

.right_side_bar {
  display: flex;
  align-items:center;
  gap: 20px;
}
.right_side_bar .batch_align {
  display: flex;
  gap: 20px;
}

.right_side_bar .batch_align img {
  height: 20px;
}
.ant-badge .ant-badge-count-sm {
    min-width: 12px;
    height: 12px;
    font-size: 9px;
    line-height: 11px;
    border-radius: 7px;
}

@media screen and (max-width:1200px) {
  .header_left_menu {
  display: block;
} 
}




@media screen and (max-width:768px) {

.header_align {
  position: relative;
    height: 100px;
    padding: 0 20px 45px 20px;
    background: #fff;
}

.serach_head_btn {
width: 100%;
    position: absolute;
    bottom: 12px;
    left: 0;
    margin: auto;
    padding: 0 16px;
}

.right_side_bar {
  margin: auto 0 auto auto;
}

main.ant-layout-content {
  padding: 120px 0px 50px !important  ;
}

.header_align .header_left img {
    height: 25px;
}
.account_name .ant-space-item {
    font-size: 12px;
}
.avator_profile {
    width: 23px;
    height: 23px;
    line-height: 21px;
    font-size: 11px;
    gap: 4px;
}

.right_side_bar {
  gap: 18px;
}
.right_side_bar .batch_align {
  gap: 15px;
}
.right_side_bar .batch_align img {
    height: 17px;
}
.ant-badge .ant-badge-count-sm {
    min-width: 11px;
    height: 11px;
    font-size: 9px;
    line-height: 10px;
    border-radius: 7px;
}

.header_align .header_left {
    width: fit-content;
    gap: 9px;
}
.header_align .header_left svg {
    font-size: 18px;
}


}


@media screen and (max-width:380px) {
  .account_name .ant-space-item {
    font-size: 10px;
}
}
`;